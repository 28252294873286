import React from 'react';
import classnames from 'classnames';

import { LoadingBehaviorOptions, ThumbnailImage } from 'wix-ui-tpa/ThumbnailImage';

import { ImageRatio } from '../../../settings/settingsConstants';
import { withAppSettings, WithAppSettingsProps } from '../../../contexts/withAppSettings';
import { GroupCardProps } from '../types';

import { classes } from './GroupCardMedia.st.css';

export interface GroupCardMediaProps {
  url: string;
  ratio: ImageRatio;
  groupUrl: string;
  goToGroup?: GroupCardProps['goToGroup'];
}
const noop = () => {};

export const GroupCardMediaComponent: React.FC<
  GroupCardMediaProps & WithAppSettingsProps
> = ({ ratio, url, goToGroup, appSettings, groupUrl }) => {
  const aspectRatio = ratio === ImageRatio.square ? 'square' : 'cinema';

  return (
    <a
      tabIndex={-1}
      href={groupUrl}
      onClick={goToGroup}
      data-hook="group-card-media"
      data-bi-origin="group_image"
      className={classnames(classes.root, {
        [classes.empty]: !url,
      })}
    >
      {
        url && <ThumbnailImage
          fluid
          src={url}
          aspectRatio={aspectRatio}
          loadingBehavior={LoadingBehaviorOptions.blur}
        />
      }
    </a>
  )
};
GroupCardMediaComponent.displayName = 'GroupCardMediaComponent';
GroupCardMediaComponent.defaultProps = {
  goToGroup: noop,
};

export const GroupCardMedia = withAppSettings(GroupCardMediaComponent);
