import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { compose } from '@wix/social-groups-common/dist/src/compose';

import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../contexts/withAppSettings';

import { Grid, GridProps } from 'wix-ui-tpa/Grid';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import withGroups from '../../contexts/withGroups';

import {
  DEFAULT_GRID_CARD_SPACING,
  LayoutType,
} from '../../settings/settingsConstants';
import { GroupCard } from '../GroupCard';
import { Button } from '../Button/Button';
import { DATA_HOOKS } from './dataHooks';
import { NoGroups } from './NoGroups';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';

import { st, classes } from './GroupList.st.css';
import { WithGroupsProps } from '../../contexts/withGroupsProps';
import { getGroupUrl } from '../utils/utils';
import { getSettingsKeyFor } from '@wix/social-groups-common/dist/src/utils/utils';
import { Spinner } from '@wix/social-groups-common/dist/src/components/Spinner/Spinner';

const { PENDING } = ApiTypes.v1.GroupApprovalStatus;

export interface GroupListProps {
  onCreateGroupClick(): void;
  withCTA?: boolean;
  goToGroup(groupId: string): void;
  mobile: boolean;
  className?: string;
}

type Props = GroupListProps &
  InjectedTranslateProps &
  WithGroupsProps &
  WithAppSettingsProps &
  WithBiLoggerProps;

export const GroupListComponent: React.FC<Props> = (props) => {
  const {
    appSettings,
    biLogger,
    goToGroup,
    groupNameQuery,
    groups,
    mobile,
    onCreateGroupClick,
    t,
    withCTA,
    className,
    groupUrls,
  } = props;
  const gapFromSettings =
    appSettings[getSettingsKeyFor('gridCardSpacing', mobile)];

  const gap =
    typeof gapFromSettings === 'number'
      ? gapFromSettings
      : DEFAULT_GRID_CARD_SPACING;

  const {
    mobileGroupCardListSpacing: rawMobileGroupCardListSpacing,
  } = appSettings.styleParams.numbers;

  const mobileGroupCardListSpacing =
    rawMobileGroupCardListSpacing == null ? 0 : rawMobileGroupCardListSpacing;

  const listGrid = {
    maxColumns: 1,
    columnGap: 0,
    rowGap: mobile ? mobileGroupCardListSpacing : 0,
  };
  const gridProps: { [key: string]: Partial<GridProps> } = {
    [LayoutType.grid]: {
      maxColumns: mobile ? 1 : 3,
      columnGap: gap,
      rowGap: gap,
    },
    [LayoutType.list]: listGrid,
    [LayoutType.listWidget]: listGrid,
  };

  const layoutType = appSettings[getSettingsKeyFor('groupListLayout', mobile)];

  if (!groups) {
    return <Spinner offset="L" label={t('groups-web.group-list.loading')} />;
  }

  const existingGroups =
    groups && groups.length
      ? groups.filter((group) => group.approvalStatus !== PENDING)
      : groups;

  if (!existingGroups.length) {
    if (groupNameQuery) {
      return (
        <NoGroups
          data-hook={DATA_HOOKS.noGroupsResult}
          emptyStateHeader={t('groups-web.search.no-results.title')}
          emptyStateText={t('groups-web.search.no-results.text')}
        />
      );
    }
    return (
      <NoGroups
        data-hook={DATA_HOOKS.noGroups}
        emptyStateHeader={t('groups-web.empty-state.header')}
        emptyStateText={t('groups-web.empty-state.text')}
        actionButton={
          withCTA ? (
            <Button
              priority={PRIORITY.primary}
              onClick={onCreateGroupClick}
              data-hook={DATA_HOOKS.noGroupsCreateButton}
            >
              {t('groups-web.empty-state.cta')}
            </Button>
          ) : null
        }
      />
    );
  }

  const onGoToGroup = (group: ApiTypes.v1.GroupResponse, event) => {
    tryToCallBi(async () => {
      await biLogger.groupsSelectGroupFromList({
        origin: event.target.dataset.biOrigin || 'group_block',
        groupId: group.groupId,
        userEntry: BIUserEntry.SITE,
      });
    });
    if (!event.target.href) {
      goToGroup(group.slug);
    }
  };

  return (
    <Grid
      {...gridProps[layoutType]}
      className={st(classes.grid, { layoutType }, className)}
    >
      {existingGroups.map((group) => {
        const groupUrl =
          appSettings.viewMode === 'Site'
            ? groupUrls[group.groupId]
            : undefined;
        return (
          <Grid.Item key={group.groupId}>
            <GroupCard
              group={group}
              goToGroup={(event) => onGoToGroup(group, event)}
              groupUrl={groupUrl}
              mobile={mobile}
            />
          </Grid.Item>
        );
      })}
    </Grid>
  );
};

const enhance = compose(withGroups, translate(), withAppSettings, withBiLogger);
export const GroupList = enhance(
  GroupListComponent,
) as React.ComponentType<GroupListProps>;

GroupList.displayName = 'GroupList';
