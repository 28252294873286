import React from 'react';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../../contexts/withAppSettings';

import { RoleBadges } from '@wix/social-groups-common/dist/src/components';
import { withTPAConfig } from '@wix/social-groups-common/dist/src/components/withTPAConfig';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { GroupPrivacyLevel } from './GroupPrivacy';
import { classes, st } from './GroupCardMeta.st.css';
import classnames from 'classnames';
import { MembersLabelWithCount } from '../../MembersLabel/MembersLabelWithCount';
import { getSettingsKeyFor } from '@wix/social-groups-common/dist/src/utils/utils';
import { isUndefinedOrTrue } from '../../utils/utils';
import { TPAComponentsConfig } from 'wix-ui-tpa/dist/es/src';
import { GROUP_TITLE_LINK } from './dataHooks';

interface GroupCardMetaProps {
  group: ApiTypes.v1.GroupResponse;
  groupUrl: string;
  className?: string;
}

type Props = GroupCardMetaProps &
  InjectedTranslateProps &
  WithAppSettingsProps &
  TPAComponentsConfig;

export const GroupCardMetaComponent: React.FC<Props> = ({
  t,
  group,
  appSettings,
  groupUrl,
  mobile,
  className,
}) => {
  const { relationship, roles, slug } = group;
  const groupWrapper = new GroupWrapper(group);
  const memberCount = groupWrapper.getMembersCount();
  const pendingMembersCount = groupWrapper.getPendingMembersCount();
  const membersName = groupWrapper.getMembersName();
  const privacyLevel = groupWrapper.getPrivacy();
  const showMembersCount =
    appSettings[getSettingsKeyFor('showMemberCount', mobile)];

  const groupType = isUndefinedOrTrue(appSettings.showGroupType);
  return (
    <div
      className={st(
        classes.root,
        { mobile },
        classnames(classes.meta, className),
      )}
    >
      <div className={classes.header}>{renderTitleLink()}</div>
      <p className={classes.details}>
        {groupType && <GroupPrivacyLevel t={t} privacyLevel={privacyLevel} />}
        {showMembersCount && groupType && <span>&nbsp;&middot;&nbsp;</span>}
        {showMembersCount && renderMembersCount()}
        {showMembersCount && renderPendingMembersCount()}
      </p>
    </div>
  );

  function renderMembersCount() {
    return (
      <MembersLabelWithCount
        groupMemberLabel={membersName}
        count={memberCount}
      />
    );
  }

  function renderPendingMembersCount() {
    return pendingMembersCount ? (
      <span>
        &nbsp;&middot;&nbsp;
        <span className={classes.pendingMembers}>
          {t('groups-web.group-list.pending-members.count', {
            count: pendingMembersCount,
          })}
        </span>
      </span>
    ) : null;
  }
  function renderTitleLink() {
    return (
      <>
        <a
          data-hook={GROUP_TITLE_LINK}
          className={classes.link}
          href={groupUrl}
          data-bi-origin="group_title"
        >
          {group.details.title}
        </a>
        {appSettings[getSettingsKeyFor('showAdminBadge', mobile)] && (
          <RoleBadges relationship={relationship} roles={roles} />
        )}
      </>
    );
  }
};

const enhance = compose(translate(), withAppSettings, withTPAConfig);

export const GroupCardMeta = enhance(GroupCardMetaComponent);
