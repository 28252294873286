import React from 'react';
import { GroupCardProps } from '../types';
import { InjectedTranslateProps, translate } from 'react-i18next';

import { PRIORITY } from 'wix-ui-tpa/Button';
import { Button } from '../../Button/Button';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { isJoined } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import {
  withGroupsActions,
  WithGroupsActionsProps,
} from '../../../contexts/withGroupsActions';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../../contexts/withAppSettings';
import { isUndefinedOrTrue } from '../../utils/utils';

const CTA_BUTTON_PREFERENCES: {
  [key in ApiTypes.v1.RelationshipWithGroup]: {
    buttonLabelKey: string;
    priority: PRIORITY;
  };
} = {
  [ApiTypes.v1.RelationshipWithGroup.NONE]: {
    buttonLabelKey: 'groups-web.btn.join-group',
    priority: PRIORITY.primary,
  },
  [ApiTypes.v1.RelationshipWithGroup.PENDING_APPROVAL]: {
    buttonLabelKey: 'groups.web.btn.join-requested',
    priority: PRIORITY.secondary,
  },
  [ApiTypes.v1.RelationshipWithGroup.JOINED]: {
    buttonLabelKey: 'groups-web.btn.view-group',
    priority: PRIORITY.secondary,
  },
  [ApiTypes.v1.RelationshipWithGroup.REJECTED_MEMBERSHIP]: {
    buttonLabelKey: 'groups-web.btn.join-group',
    priority: PRIORITY.primary,
  },
};

type Props = Omit<GroupCardProps, 'imageRatio'> &
  InjectedTranslateProps &
  WithBiLoggerProps &
  WithGroupsActionsProps &
  WithAppSettingsProps & { groupUrl?: string; className?: string };

const GroupCardCTAComponent: React.FC<Props> = ({
  t,
  biLogger,
  group,
  actions: { handleGroupCardCTA },
  appSettings,
  groupUrl,
  className,
}) => {
  if (!isUndefinedOrTrue(appSettings.showButton)) {
    return null;
  }
  const ctaPreferences = CTA_BUTTON_PREFERENCES[group.relationship];

  const onClickHandler = (event) => {
    event.stopPropagation();
    if (isJoined(group)) {
      tryToCallBi(async () => {
        await biLogger.groupsSelectGroupFromList({
          origin: 'view_btn',
          userEntry: BIUserEntry.SITE,
          groupId: group.groupId,
        });
      });
      if (groupUrl) {
        window.location.href = groupUrl;
      }
    }
    handleGroupCardCTA(group);
  };

  return (
    <div className={className}>
      <Button
        data-hook="cta-button"
        priority={ctaPreferences.priority}
        onClick={onClickHandler}
        fullWidth
      >
        {t(ctaPreferences.buttonLabelKey)}
      </Button>
    </div>
  );
};

const enhance = compose(
  translate(),
  withGroupsActions,
  withBiLogger,
  withAppSettings,
);

export const GroupCardCTA = enhance(GroupCardCTAComponent);
GroupCardCTA.displayName = 'GroupCardCTA';
